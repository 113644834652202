import { HttpMethod } from "@app/enums";
import { IHttpClient } from "@app/interfaces";
import { HttpResonse } from "@app/interfaces";

export class HttpClient implements IHttpClient {
  public async request<T extends Record<string, any>>(
    url: string,
    method: HttpMethod = HttpMethod.GET,
    body?: Record<string, any>,
    timeout: number = 10000,
  ): Promise<HttpResonse<T>> {
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), timeout);

    try {
      const response = await fetch(url, {
        method,
        signal: abortController.signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: body ? JSON.stringify(body) : null,
      });

      return this.formatResponse<T>(response);
    } finally {
      clearTimeout(timeoutId);
    }
  }

  private async formatResponse<T extends Record<string, any>>(response: Response): Promise<HttpResonse<T>> {
    const data = (await this.parseToJson<T>(response)) || ({} as T); // Значение по умолчанию
    return { data, ok: response.ok, status: response.status, statusText: response.statusText };
  }

  private async parseToJson<T extends Record<string, any>>(response: Response): Promise<T | null> {
    try {
      return await response.json();
    } catch (e) {
      console.warn("Failed to parse response as JSON");
      return null;
    }
  }
}
