import { HttpMethod } from "@app/enums";
import { IHttpClient, HttpResonse } from "@app/interfaces";
import { FetchOptions } from "./interfaces/fetch-options.interface";
import { RequestStrategy } from "./interfaces/request-strategy.interface";
import {
  ParallelFirstSuccessStrategy,
  PrimaryOnlyStrategy,
  SequentialFirstSuccessStrategy,
} from "./request-strategies"; // Импортируем стратегии

export class HttpProvider {
  constructor(
    private readonly client: IHttpClient,
    apiUrls: string[],
  ) {
    this.strategies = {
      parallel_first_success: new ParallelFirstSuccessStrategy(apiUrls),
      primary_only: new PrimaryOnlyStrategy(apiUrls),
      sequential_first_success: new SequentialFirstSuccessStrategy(apiUrls),
    };
  }

  private strategies: Record<string, RequestStrategy>;

  public async get<T extends Record<string, any>>(
    url: string,
    options: FetchOptions = { method: HttpMethod.GET, strategy: "parallel_first_success" },
  ): Promise<HttpResonse<T>> {
    const strategy = this.getStrategy(options.strategy);
    return strategy.execute(url, this.client, { ...options, method: HttpMethod.GET });
  }

  public async post<T extends Record<string, any>>(
    url: string,
    body: object,
    options: FetchOptions = { method: HttpMethod.POST, strategy: "sequential_first_success" },
  ): Promise<HttpResonse<T>> {
    const strategy = this.getStrategy(options.strategy);
    return strategy.execute(url, this.client, {
      ...options,
      method: HttpMethod.POST,
      body,
    });
  }

  // Метод для получения стратегии из кэша
  private getStrategy(strategy?: string): RequestStrategy {
    return this.strategies[strategy!] || this.strategies["primary_only"];
  }
}
