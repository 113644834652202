export function parseJson<T extends Record<string, any>>(rawData: string): T | null {
  let result: T | null;

  try {
    result = new Object(JSON.parse(rawData)) as T;
  } catch (e) {
    result = null;
  }

  return result;
}