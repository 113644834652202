import { Module } from "@app/abstracts";

export class ModuleProvider {
  constructor() {}

  public readonly modules: (Module & any)[] = [];

  public async register<T, M>(moduleName: string, ...args: T[]): Promise<Module & M> {
    const foundModule = this.modules.find(module => module.name == moduleName);
    if (foundModule) return foundModule;
    const module = await import(`@modules/${moduleName}/index.ts`);

    const ModuleConstructor = module.default as { new (...args: any[]): Module & M };
    const instance = new ModuleConstructor(...args);
    this.modules.push(instance);
    return instance;
  }

  public add<M>(moduleInstance: Module & M): Module & M {
    const foundModule = this.modules.find(module => module.name == moduleInstance.name);
    if (foundModule) return foundModule;

    this.modules.push(moduleInstance);
    return moduleInstance;
  }
}
