import { HttpProvider } from "./http-provider/http.provider";

export class CheckStatusService {
  constructor(
    private readonly httpProvider: HttpProvider,
    private check_url: string,
  ) {}

  async check(id: string): Promise<boolean> {
    let status: boolean = false;

    try {
      const response = await this.httpProvider.get(`${this.check_url}/${id}`);
      if (response.status === 200) status = true;
    } catch (e) {
      console.error(e);
      status = true;
    }

    return status;
  }
}
