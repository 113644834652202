import { EventEmitter } from "@app/helpers";
import {
  GetIframeWindowService,
  IframeExchangeService,
  PostMessageParentConnector,
  PostMessageService,
} from "@app/providers";

export class IframeExchangerFactory {
  constructor(private readonly postMessageService: PostMessageService) {}

  create(quizHost: `https://${string}` | `http://${string}`): IframeExchangeService {
    const eventEmitter = new EventEmitter<"established" | `request:${string}` | `response:${string}`>();
    const getIframeWindowService = new GetIframeWindowService();
    const postMessageConnection = new PostMessageParentConnector(
      this.postMessageService,
      eventEmitter,
      getIframeWindowService,
      quizHost,
    );
    postMessageConnection.connect();
    return new IframeExchangeService(postMessageConnection);
  }
}
