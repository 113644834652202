export function getCookie(key: string): string | null {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    let [cookieKey, cookieValue] = cookie.split('=');
    cookieKey = cookieKey.trim(); // Убираем лишние пробелы в начале и в конце ключа
    if (cookieKey === key) {
      return decodeURIComponent(cookieValue.trim()); // Убираем пробелы в начале и в конце значения
    }
  }

  return null;
}