import { QuizOptions } from "@app/interfaces";
import { appConfig } from "../app.config";
import { Service } from "@app/enums";

const DEFAULT_SERVICELIST = [
  Service.IMPLANTATION,
  Service.PROSTHETICS,
  Service.BRACES,
  Service.VENEERS,
  Service.WHITENING,
  Service.TREATMENT,
  Service.REMOVE,
  Service.PEDIATRICS,
  Service.GUMS,
  Service.CROWNS,
];

type DefaultQuizOptionsType = Required<
  Pick<
    QuizOptions,
    | "scenario"
    | "autopopupTimeout"
    | "autopopupLimit"
    | "initialPageIndex"
    | "openOnScroll"
    | "serviceList"
    | "quiz_host"
    | "customContent"
  >
>;

export const defaultQuizOptions: DefaultQuizOptionsType = {
  scenario: "popup",
  autopopupTimeout: 10000,
  autopopupLimit: 1,
  initialPageIndex: 0,
  openOnScroll: false,
  serviceList: DEFAULT_SERVICELIST,
  quiz_host: appConfig().quiz_url,
  customContent: {},
};
