import { Scenario } from "../../shared/types/scenario.type";
import { EventEmitter } from "@app/helpers";
import { DeviceInfoProvier } from "@app/providers";
import styles from "./iframe.module.scss";

export default class Iframe {
  constructor(
    private readonly url: string,
    private readonly scenary: Scenario,
    private iframeSelector?: string,
  ) {}

  public element_id = "dentolo_iframe";
  private iframe: HTMLIFrameElement;
  private wrapper: HTMLDivElement = document.createElement("div");
  private mainElement: HTMLElement;
  private loaded: boolean = false;
  private opened: boolean = false;
  private eventEmitter = new EventEmitter<"load">();

  public window: Window | null;

  public createElement(): HTMLIFrameElement {
    switch (this.scenary) {
      case "custom":
        this.iframe = this.handleCustomScenario(this.iframeSelector);
        break;

      case "fullPage":
        this.iframe = this.handleFullPageScenario();
        break;

      case "popup":
      default:
        this.iframe = this.handlePopupScenario();
        break;
    }

    // Общие инструкции для всех сценариев
    this.iframe.src = this.url;
    this.setupOnLoadHandler();
    return this.iframe;
  }

  public async appendToPage(): Promise<void> {
    return new Promise<void>(resolve => {
      if (/complete|interactive|loaded/.test(document.readyState)) {
        document.body.appendChild(this.mainElement);
        resolve();
      } else {
        document.addEventListener("DOMContentLoaded", () => {
          document.body.appendChild(this.mainElement);
          resolve();
        });
      }
    });
  }

  public onContentLoaded(callback: () => void) {
    this.eventEmitter.once("load", callback);
  }

  //todo: доработать, чтобы повторно не открывались и не закрывались, если уже закрыты или открыты
  public show(): void {
    if (this.isOpened() || this.scenary == "custom") return;

    this.mainElement.classList.add(styles.active);
    setTimeout(() => {
      this.mainElement.classList.add(styles.visible);
      this.iframe.style.height = "100%";
      this.opened = true;
    }, 100);
  }

  public hide(): void {
    if (!this.isOpened() || this.scenary == "custom") return;

    this.mainElement.classList.remove(styles.visible);
    setTimeout(() => {
      this.mainElement.classList.remove(styles.active);
      this.opened = false;
    }, 400);
  }

  public isOpened(): boolean {
    return this.opened;
  }

  private handleCustomScenario(iframeSelector?: string): HTMLIFrameElement {
    if (!iframeSelector) {
      throw new Error("iframeSelector is required for custom scenario");
    }

    const iframe = this.findExistingIframe(iframeSelector) || this.createNewIframe();
    iframe.classList.add(styles.custom);
    this.mainElement = iframe;

    return iframe;
  }

  private handleFullPageScenario(): HTMLIFrameElement {
    const iframe = this.createNewIframe();
    this.setCommonAttributes(iframe);
    iframe.classList.add(styles.fullPage);

    this.mainElement = DeviceInfoProvier.ios() ? this.wrapInIosWrapper(iframe) : iframe;

    return iframe;
  }

  private handlePopupScenario(): HTMLIFrameElement {
    const iframe = this.createNewIframe();
    this.setCommonAttributes(iframe);

    this.mainElement = DeviceInfoProvier.ios() ? this.wrapInIosWrapper(iframe) : iframe;

    return iframe;
  }

  private findExistingIframe(iframeSelector: string): HTMLIFrameElement | null {
    const iframe = document.querySelector<HTMLIFrameElement>(iframeSelector);
    if (iframe) {
      iframe.id = iframe.id || this.element_id;
      return iframe;
    }
    return null;
  }

  private createNewIframe(): HTMLIFrameElement {
    const iframe = document.createElement("iframe");
    iframe.id = this.element_id;
    return iframe;
  }

  private setCommonAttributes(iframe: HTMLIFrameElement) {
    iframe.title = "Dentolo Quiz";
    iframe.name = "dentolo-iframe";
    iframe.className = styles.default;
  }

  private wrapInIosWrapper(iframe: HTMLIFrameElement): HTMLDivElement {
    this.wrapper.className = styles.wrapper;
    iframe.className = styles.iframe;
    this.wrapper.appendChild(iframe);
    return this.wrapper;
  }

  private setupOnLoadHandler() {
    this.iframe.onload = this.handleIframeLoad.bind(this);
  }

  private handleIframeLoad(event: Event) {
    this.loaded = true;
    this.eventEmitter.emit("load");
    this.window = this.iframe.contentWindow;
  }
}
