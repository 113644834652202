export enum Service {
  IMPLANTATION = "implantation",
  PROSTHETICS = "prosthetics",
  BRACES = "braces",
  VENEERS = "veneers",
  WHITENING = "whitening",
  TREATMENT = "treatment",
  REMOVE = "remove",
  PEDIATRICS = "pediatrics",
  GUMS = "gums",
  CROWNS = "crowns",
  CLEANING = "cleaning",
  REMOVE_WISDOM = "rem_wisdom",
  ONLY_BRACES = "only_braces",
  ONLY_ELINERS = "only_eliners",
  BITE = "bite",
  NO_PALATE_PROSTHETICS = "no_palate_prosthetics",
  CHEAP_PROSTHETICS = "cheap_prosthetics",
  PED_ORTHODONTICS = "ped_orthodontics",
  PEDIATRICA_VAR2 = "pediatrics_var2",
  GNATHOLOGY = "gnathology_simple",
  GNATHOLOGY_VAR2 = "gnathology_var2",
}
