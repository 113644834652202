import { not } from "@app/utils/not";
import { Emittable } from "./interfaces/emittable.interface";
import { Subscriber } from "./interfaces/subscriber.interface";

export class EventEmitter<E = string> implements Emittable<E> {
  constructor() {}

  private subscribers: Subscriber<E>[] = [];

  public subscribe<T = any>(event: E, callback: (data: T) => void): Subscriber<E> {
    const subscriber = this.createSubscription(event, callback);
    this.subscribers.push(subscriber);
    return subscriber;
  }

  public unsubscribe<T = any>(event: E, callback: (data: T) => void): void {
    this.subscribers = this.subscribers.filter(subscriber => {
      if (subscriber.event !== event || subscriber.callback !== callback) return true;
    });
  }

  public once<T = any>(event: E, callback: (data: T) => void): Subscriber<E> {
    const subscriber = this.createSubscription(event, callback, true);
    this.subscribers.push(subscriber);
    return subscriber;
  }

  public emit<T = any>(event: E, data?: T): void {
    const targetSubscriptions = this.subscribers.filter(sub => sub.event == event);
    targetSubscriptions.forEach(subscription => subscription.callback(data));
    const onceSubscriptions = targetSubscriptions.filter(sub => sub.once);

    if (onceSubscriptions.length > 0) {
      this.subscribers = this.subscribers.filter(sub => not(onceSubscriptions.includes(sub)));
    }
  }

  private createSubscription<T = any>(event: E, callback: (data: T) => void, once?: boolean): Subscriber<E> {
    const cancelCallback = this.createCancelCallback(event, callback);
    return { event, callback, cancel: cancelCallback, once };
  }

  private createCancelCallback<T = any>(event: E, callback: (data: T) => void) {
    return () => this.unsubscribe(event, callback);
  }
}
