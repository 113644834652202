export class ScrollPositionProvider {
  static set currentYPosition(position: number) {
    document.documentElement.scrollTop = position;
    document.body.scrollTop = position;
  }

  static get currentYPosition(): number {
    return window.pageYOffset || document.documentElement.scrollTop;
  }
}
