import { AnalyticsType } from "./enums/analytics-type.enum";
import { AnalyticsData } from "./interfaces/analytics-data.interface";
import { YaMetrikaOptions } from "./interfaces/ya-metrika-options.interface";
import { Analytics } from "./interfaces/analytics.interface";
import { QuizEvent } from "@app/enums";
import { App } from "@app/interfaces";
import { DentoloYaMetrika } from "./systems/dentolo-ya-metrika";
import { YaMetrikaCounterFactory } from "./systems/ya-metrika-counter.factory";
import { YaMetrikaCounter } from "./systems/ya-metrika";
import { Module } from "@app/abstracts";

export class AnalyticsModule extends Module {
  constructor(private readonly datas: AnalyticsData[]) {
    super();
  }

  private systems: Analytics[] = [];
  public readonly name = "analytics";

  protected async setup(quiz: App) {
    this.datas.forEach(data => this.addSystem(data));

    await Promise.all(this.systems.map(system => system.init(quiz)));

    Object.values(QuizEvent).forEach(event => this.bindEvent(quiz, event));

    return;
  }

  private addSystem(data: AnalyticsData) {
    switch (data.type) {
      case AnalyticsType.YA_METRIKA: {
        return this.systems.push(new YaMetrikaCounter(data.options as YaMetrikaOptions));
      }

      case AnalyticsType.DENTOLO_YA_METRIKA: {
        return this.systems.push(new DentoloYaMetrika());
      }

      default:
        return;
    }
  }

  private bindEvent(quiz: App, event: QuizEvent) {
    switch (event) {
      case QuizEvent.SEND_PARAMS:
        quiz.bind(event, params => this.setParams(params));
        break;
      case QuizEvent.SEND_ECOMMERCE:
        quiz.bind(event, data => this.setEcommerce(data));
        break;
      default:
        quiz.bind(event, params => this.track(event, params));
    }
  }

  private track(event: QuizEvent, params?: Record<string, any>) {
    this.systems.forEach(system => system.track(event, params));

    if (event == QuizEvent.REACH_QUESTION) {
      if (params?.question) {
        this.systems.forEach(system => system.track(event + "_" + params.question));
      }
    }
  }

  private setParams(params: Record<string, any>) {
    this.systems.forEach(system => system.setParams(params));
  }

  private setEcommerce(data: Record<string, any>) {
    this.systems.forEach(system => {
      if (system.setEcommerce) system.setEcommerce(data);
    });
  }
}
