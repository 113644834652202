import { IStateProvider } from "@app/interfaces";

export class StateProvider<T> implements IStateProvider<T> {
  constructor(private state: Readonly<T>) {}

  public get<K extends keyof T>(key: K): T[K] {
    return this.state[key];
  }

  public set<K extends keyof T>(key: K, value: T[K]): void {
    this.state = { ...this.state, [key]: value };
  }
}
