import { parseJson } from "@app/utils/parse-json";
import { EventEmitter } from "@app/helpers/event-emitter/event-emitter";
import { Subscriber } from "@app/helpers/event-emitter/interfaces/subscriber.interface";

interface IPostMessageService {
  send<D>(data: D, destination: Pick<Window, "postMessage">): void;
  listen<D>(callback: (data: D) => void): Subscriber;
}

export class PostMessageService {
  constructor() {
    window.addEventListener("message", ({ data }) => {
      const parsedData: Record<string, any> | null = parseJson(data);
      if (parsedData) {
        this.eventEmitter.emit("message", parsedData);
      }
    });
  }

  private eventEmitter = new EventEmitter<"message">();

  public send<D>(data: D, destination: Pick<Window, "postMessage">) {
    destination.postMessage(JSON.stringify(data), "*");
  }

  public listen<D>(callback: (data: D) => void) {
    return this.eventEmitter.subscribe("message", callback);
  }
}
