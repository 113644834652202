export class GetIframeWindowService {
  constructor() {}

  public async get(url: string): Promise<Window> {
    const iframe = document.querySelector(`iframe[src="${url}"]`) as HTMLIFrameElement;

    if (!iframe) {
      throw new Error(`Iframe with URL ${url} not found.`);
    }

    if (iframe.contentWindow) {
      return iframe.contentWindow;
    }

    await new Promise<void>((resolve, reject) => {
      iframe.addEventListener("load", () => {
        if (iframe.contentWindow) {
          resolve();
        } else {
          reject(new Error("Iframe contentWindow is still null after load event."));
        }
      });
    });

    if (!iframe.contentWindow) {
      throw new Error("Iframe contentWindow is still null after load event.");
    }

    return iframe.contentWindow;
  }
}
