export const appConfig = () => ({
  env: process.env.NODE_ENV,
  quiz_url: String(process.env.QUIZ_URL) as `https://${string}`,
  check_url: String(process.env.CHECK_URL),
  ya_metrika_counter: Number(process.env.YA_METRIKA_NUMBER),
  ya_metrika_webvisor: Boolean(Number(process.env.YA_METRIKA_WEBVISOR)),
  ya_metrika_webvisor_whitelist: String(process.env.YA_METRIKA_WEBVISOR_WHITELIST).split(", ") || [],
  api_urls: String(process.env.API_URLS).split(","),
});

export type AppConfig = ReturnType<typeof appConfig>;
