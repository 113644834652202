import { EventEmitter } from "@app/helpers";
import { ScrollPositionProvider } from "./scroll-position.provider";

export type ScrollEvent = "enter_bottom" | "leave_bottom";

export class ScrollHandlerProvider {
  private emitter = new EventEmitter<ScrollEvent>();

  constructor(private readonly bottomThreshold: number = 0.9) {
    window.addEventListener("scroll", this.handler);
  }

  private handler = () => {
    const bottom = ScrollPositionProvider.currentYPosition + window.innerHeight;
    const thresholdValue = bottom * (2 - this.bottomThreshold);
    const eventName: ScrollEvent =
      thresholdValue >= document.body.offsetHeight ? "enter_bottom" : "leave_bottom";

    this.emitter.emit(eventName);
  };

  public on(event: ScrollEvent, callback: () => void) {
    return this.emitter.subscribe(event, callback);
  }

  public once(event: ScrollEvent, callback: () => void) {
    return this.emitter.once(event, callback);
  }
}
