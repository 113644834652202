import {
  boolean,
  number,
  string,
  object,
  optional,
  record,
  defaulted,
  enums,
  array,
  any,
  Infer,
  define,
} from "superstruct";
import { defaultQuizOptions } from "../default-quiz-options";
import { whatsappSchema } from "./whatsapp.schema";
import { customContentSchema } from "./custom-content.schema";
import { Service } from "@app/enums";

// Определяем собственные типы
const ScenarioStruct = enums(["popup", "fullPage", "custom"]);

// Определяем схему closeButton
const closeButtonSchema = object({
  show: boolean(),
  onClick: string(),
  target: enums(["_self", "_blank"]),
});

const UrlStruct = define<`https://${string}` | `http://${string}`>("Url", value =>
  typeof value === "string" && /^https?:\/\/.*/.test(value) ? true : `Expected URL starting with https://`,
);
// Определяем основную схему quizOptions
export const quizOptionsSchema = object({
  clientId: string(),
  scenario: defaulted(ScenarioStruct, defaultQuizOptions.scenario),
  serviceList: defaulted(array(enums(Object.values(Service))), defaultQuizOptions.serviceList),
  autopopupTimeout: defaulted(number(), defaultQuizOptions.autopopupTimeout),
  autopopupLimit: defaulted(number(), defaultQuizOptions.autopopupLimit),
  openOnScroll: defaulted(boolean(), defaultQuizOptions.openOnScroll),
  initialPageIndex: defaulted(number(), defaultQuizOptions.initialPageIndex),
  quiz_host: defaulted(UrlStruct, defaultQuizOptions.quiz_host),
  whatsApp: optional(whatsappSchema),
  telegram: optional(record(string(), any())),
  customContent: defaulted(customContentSchema, defaultQuizOptions.customContent),
  closeButton: optional(closeButtonSchema),
  customLeadParams: optional(record(string(), any())),
  bonuses: optional(boolean()),
  iframe: optional(string()),
  commonServiceList: optional(array(enums(Object.values(Service)))),
  showCommonServiceList: optional(boolean()),
  test: optional(boolean()),
  startPageAutoAction: optional(
    object({
      action: enums(["start_quiz", "open_sign_up"]),
      timer: number(), // seconds
    }),
  ),
});

// Тип для QuizOptionsSchema
export type QuizOptionsSchema = Infer<typeof quizOptionsSchema>;
