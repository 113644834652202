import { appConfig } from "../../../app.config";
import { Analytics } from "../interfaces/analytics.interface";
import { App } from "@app/interfaces";
import { YaMetrikaCounterFactory } from "./ya-metrika-counter.factory";

export class DentoloYaMetrika implements Analytics {
  constructor() {}

  private yaMetrika: ReturnType<(typeof YaMetrikaCounterFactory)["create"]>;

  public async init(quiz: App) {
    this.yaMetrika = YaMetrikaCounterFactory.create({
      counter: appConfig().ya_metrika_counter,
      webvisor: this.getWebvisorStatus(),
    });

    return this.yaMetrika.init(quiz);
  }

  public track(event: string, params?: Record<string, any>) {
    this.yaMetrika.track(event, params);
  }

  public setParams(params: Record<string, any>) {
    this.yaMetrika.setParams(params);
  }

  public setEcommerce(data: Record<string, any>) {
    this.yaMetrika.setEcommerce(data);
  }

  private getWebvisorStatus(): boolean {
    if (appConfig().ya_metrika_webvisor) {
      const whitelist = appConfig().ya_metrika_webvisor_whitelist;

      if (whitelist.length) {
        const hasMatch = whitelist.some(value => window.location.href.includes(value));
        if (hasMatch) return true;

        return false;
      } else return true;
    } else return false;
  }
}
