export class QuizState {
  readonly status: "opened" | "closed" = "closed";
  readonly wasOpened: boolean = false;
  readonly wasClosed: boolean = false;
  readonly wasLoaded: boolean = false;
  readonly leadReceived: boolean = false;
  readonly initialized: boolean = false;
  readonly autoOpenTimeoutId?: NodeJS.Timeout = undefined;
  readonly memorizedScrollPosition: number = 0;
  readonly servicesNum: number = 0;
  readonly theme: "light" | "dark" = "light";
  readonly queryParams: Record<string, any> = {};
  readonly lastPageYPosition: number = 0;
}
