import "./polyfills";

import { EventEmitter } from "@app/helpers";
import { QuizFactory } from "./core/quiz-factory";
import { QuizState } from "./core/quiz-state";
import { QuizEvent } from "@app/enums";
import { App, QuizOptions } from "@app/interfaces";
import {
  HttpProvider,
  ModuleProvider,
  PostMessageService,
  StateProvider,
  ScrollHandlerProvider,
  StorageProvider,
} from "@app/providers";
import { appConfig } from "./app.config";
import { IframeExchangerFactory } from "./core/iframe-exchanger-factory";
import { CheckStatusService } from "@app/providers/check-status.service";
import { HttpClient } from "@app/providers";
import { SentryService } from "@app/providers/sentry.service";

declare global {
  interface Window {
    dentolo_quiz: App;
    DentoloQuiz: (options: QuizOptions) => App;
  }
}

function bootstrap() {
  const sentryService = new SentryService(new EventEmitter());

  sentryService.init({
    dsn: "https://28d78afed16a8f9b5c967c5e949c7eb6@o394454.ingest.us.sentry.io/4507390541299712",
    release: "v3",
    tracesSampleRate: 1.0,
  });

  const storageProvider = new StorageProvider();
  const quizEventEmitter = new EventEmitter<QuizEvent>();
  const quizStateProvider = new StateProvider<QuizState>(new QuizState());
  const postMessageService = new PostMessageService();
  const moduleProvider = new ModuleProvider();
  const httpClient = new HttpClient();
  const httpProvider = new HttpProvider(httpClient, appConfig().api_urls);
  const checkStatusService = new CheckStatusService(httpProvider, appConfig().check_url);
  const iframeExchangerFactory = new IframeExchangerFactory(postMessageService);
  const scrollHandlerProvider = new ScrollHandlerProvider();

  const quizFactory = new QuizFactory(
    storageProvider,
    quizEventEmitter,
    iframeExchangerFactory,
    quizStateProvider,
    checkStatusService,
    moduleProvider,
    scrollHandlerProvider,
    sentryService,
  );

  window.DentoloQuiz = (options: QuizOptions) => quizFactory.create(options);
}

bootstrap();
