import { Connector } from "@app/interfaces/connector.interface";
import { CustomContent, InitDto, SendCustomDataDto } from "@app/interfaces";

export enum OutgoingPattern {
  INIT = "init",
  OPEN = "open",
  CUSTOM_LEAD_PARAMS = "CUSTOM_LEAD_PARAMS",
  SET_HEADER_PHONE = "REPLACE_PHONE",
  SET_DESCRIPTOR = "REPLACE_DESCRIPTOR",
  SEND_DATA = "SEND_DATA",
  ENABLE_WEBVISOR = "ENABLE_WEBVISOR",
  SEND_CUSTOM_DATA = "send_custom_data",
  SEND_CUSTOM_CONTENT = "send_custom_content",
}

export enum IncomingPattern {
  CLOSE = "close",
  QUIZ_EVENT = "quiz_event",
}

export class IframeExchangeService {
  constructor(private readonly postMessageConnector: Connector) {}

  public on<T>(pattern: IncomingPattern, callback: (data: T) => void) {
    this.postMessageConnector.on(pattern, callback);
  }

  public init(data: InitDto): Promise<void> {
    return this.postMessageConnector.send(OutgoingPattern.INIT, data);
  }

  public open(data: any): Promise<void> {
    return this.postMessageConnector.send(OutgoingPattern.OPEN, data);
  }

  public sendCustomLeadParams(data: any): Promise<void> {
    return this.postMessageConnector.send(OutgoingPattern.CUSTOM_LEAD_PARAMS, data);
  }

  public sendCustomData<R>(data: SendCustomDataDto<Record<string, any>>): Promise<R> {
    return this.postMessageConnector.send(OutgoingPattern.SEND_CUSTOM_DATA, data);
  }

  public setHeaderPhone(data: any): Promise<void> {
    return this.postMessageConnector.send(OutgoingPattern.SET_HEADER_PHONE, data);
  }

  public setDescriptor(data: any): Promise<void> {
    return this.postMessageConnector.send(OutgoingPattern.SET_DESCRIPTOR, data);
  }

  public enableWebvisor(data: any): Promise<void> {
    return this.postMessageConnector.send(OutgoingPattern.ENABLE_WEBVISOR, data);
  }

  public sendData(customData: { cmd: string; data: any }) {
    return this.postMessageConnector.send(OutgoingPattern.SEND_DATA, customData);
  }

  public sendCustomContent(customContent: CustomContent) {
    return this.postMessageConnector.send(OutgoingPattern.SEND_CUSTOM_CONTENT, customContent);
  }
}
