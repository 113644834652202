import { HttpMethod } from "@app/enums/http-method.enum";
import { IHttpClient, HttpResonse } from "@app/interfaces";
import { FetchOptions } from "./interfaces/fetch-options.interface";
import { RequestStrategy } from "./interfaces/request-strategy.interface";

export class ParallelFirstSuccessStrategy implements RequestStrategy {
  constructor(private readonly apiUrls: string[]) {}

  async execute<T extends Record<string, any>>(
    path: string,
    client: IHttpClient,
    options: FetchOptions & { method: HttpMethod },
  ): Promise<HttpResonse<T>> {
    return Promise.any(
      this.apiUrls.map(url => client.request<T>(url + path, options.method, options.body, options.timeout)),
    );
  }
}

export class PrimaryOnlyStrategy implements RequestStrategy {
  constructor(private readonly apiUrls: string[]) {}

  async execute<T extends Record<string, any>>(
    path: string,
    client: IHttpClient,
    options: FetchOptions & { method: HttpMethod },
  ): Promise<HttpResonse<T>> {
    return client.request<T>(this.apiUrls[0] + path, options.method, options.body, options.timeout);
  }
}

export class SequentialFirstSuccessStrategy implements RequestStrategy {
  constructor(private readonly apiUrls: string[]) {}

  async execute<T extends Record<string, any>>(
    path: string,
    client: IHttpClient,
    options: FetchOptions & { method: HttpMethod },
  ): Promise<HttpResonse<T>> {
    for (const url of this.apiUrls) {
      try {
        return await client.request<T>(url + path, options.method, options.body, options.timeout);
      } catch (e) {
        console.error(`Failed to fetch from ${url}: ${e}`);
      }
    }
    throw new Error("All requests failed");
  }
}
