import { Emittable } from "@app/helpers/event-emitter/interfaces/emittable.interface";

export class SentryService {
  constructor(private readonly eventEmitter: Emittable<"init" | "fail-init">) {}

  private sentry: typeof import("@sentry/browser");
  private isInited: boolean = false;

  async init(options: Parameters<typeof import("@sentry/browser").init>[0]) {
    try {
      this.sentry = await import("@sentry/browser");

      this.sentry.init({
        ...options,
        integrations: [this.sentry.browserTracingIntegration()],
      });
      this.eventEmitter.emit("init");
      this.isInited = true;
    } catch (e) {
      this.eventEmitter.emit("fail-init");
      console.error(e);
      console.log("Error in Sentry init. Error handled");
    }
  }

  async captureException(e: any) {
    try {
      if (!this.isInited) {
        await new Promise((resolve, reject) => {
          this.eventEmitter.subscribe("init", resolve);
          this.eventEmitter.subscribe("fail-init", reject);
        });
      }
      this.sentry?.captureException(e);
    } catch (e) {
      console.error(e);
    }
  }
}
