import { DeviceInfoProvier } from "./device-info.provider";

export class PageOverflowProvider {
  private static readonly IOS_BODY_STYLES = {
    height: "100%",
    position: "static",
    overflow: "hidden",
  };

  private static readonly DEFAULT_STYLES = {
    height: "100%",
    position: "static",
    overflow: "hidden",
  };

  static addOverflowHidden(): void {
    if (DeviceInfoProvier.ios()) {
      this.applyStyles(document.body, this.IOS_BODY_STYLES);
    }
    this.applyStyles(document.documentElement, this.DEFAULT_STYLES);
  }

  static removeOverflowHidden(): void {
    if (DeviceInfoProvier.ios()) {
      this.resetStyles(document.body);
    }
    this.resetStyles(document.documentElement);
  }

  private static applyStyles(element: HTMLElement, styles: Record<string, string>): void {
    Object.keys(styles).forEach(key => {
      element.style[key as any] = styles[key];
    });
  }

  private static resetStyles(element: HTMLElement): void {
    element.style.height = "";
    element.style.position = "";
    element.style.overflow = "";
  }
}
