import { QuizOptionsSchema } from "./validators/quiz-options.schema";
import { getCookie } from "@app/utils/get-cookie";
import { getQueryParam } from "@app/utils/get-query-param";
import { ScrollHandlerProvider } from "../shared/providers/scroll-handler.provider";
import {
  DeviceInfoProvier,
  IframeExchangeService,
  IncomingPattern,
  ModuleProvider,
  PageOverflowProvider,
  ScrollPositionProvider,
  StorageProvider,
} from "@app/providers";
import { QuizEvent } from "@app/enums";
import { App, IStateProvider, SendCustomDataDto, QuizEventData } from "@app/interfaces";
import { ModuleName, Scenario } from "@app/types";
import { isPromise } from "@app/utils/is-promise";
import { Emittable } from "@app/helpers/event-emitter/interfaces/emittable.interface";
import Iframe from "../components/iframe/iframe.model";
import { QuizState } from "./quiz-state";
import { CheckStatusService } from "@app/providers/check-status.service";
import { getQueryParams } from "@app/utils";
import { Module } from "@app/abstracts";

const QUIZ_DISABLED_WARN = "dentolo quiz is disabled for this client";

export class QuizApp implements App {
  constructor(
    private readonly iframe: Iframe,
    private readonly quizOptions: QuizOptionsSchema,
    private readonly quizEventEmitter: Emittable<QuizEvent>,
    private readonly stateProvider: IStateProvider<QuizState>,
    private readonly storageProvider: StorageProvider,
    private readonly checkStatusService: CheckStatusService,
    private readonly iframeExchangeService: IframeExchangeService,
    private readonly moduleProvider: ModuleProvider,
    private readonly scrollHandlerProvider: ScrollHandlerProvider,
  ) {}

  public async init(): Promise<void> {
    this.checkStatus();
    this.setInitialState(this.quizOptions);

    this.setupEventHandlers();
    await this.initIframe();
    this.handleScenario(this.quizOptions.scenario);

    this.stateProvider.set("initialized", true);

    this.quizEventEmitter.emit(QuizEvent.INIT);
    this.quizEventEmitter.emit(QuizEvent.SEND_PARAMS, {
      servicesNum: this.stateProvider.get("servicesNum"),
      theme: this.stateProvider.get("theme"),
    });
  }

  public close() {
    if (this.stateProvider.get("status") == "closed") return;
    this.iframe.hide();
    this.stateProvider.set("status", "closed");
    this.quizEventEmitter.emit(QuizEvent.CLOSE);
  }

  public async open(index: number = this.quizOptions.initialPageIndex, trigger?: any) {
    if (this.stateProvider.get("status") == "opened") return;

    if (!this.stateProvider.get("initialized")) {
      await new Promise<void>(resolve => {
        this.quizEventEmitter.once(QuizEvent.INIT, () => resolve());
      });
    }

    this.stateProvider.set("wasOpened", true);
    this.stateProvider.set("status", "opened");

    this.iframe.show();
    this.iframeExchangeService.open({ initialPageIndex: index, openTrigger: trigger });

    this.quizEventEmitter.emit(QuizEvent.OPEN, {
      initialPageIndex: index,
      openTrigger: trigger,
    });
  }

  public async sendCustomLeadParams(customLeadParams: Record<string, any>): Promise<void> {
    return this.iframeExchangeService.sendCustomLeadParams(customLeadParams);
  }

  public sendCustomData<R>(data: SendCustomDataDto<Record<string, any>>): Promise<R> {
    return this.iframeExchangeService.sendCustomData(data);
  }

  public setAutopopupTimeout(milliseconds: number = this.quizOptions.autopopupTimeout): void {
    let autoOpenTimeoutId = this.stateProvider.get("autoOpenTimeoutId");

    if (autoOpenTimeoutId) {
      clearTimeout(autoOpenTimeoutId);
      this.stateProvider.set("autoOpenTimeoutId", undefined);
    }

    if (milliseconds !== 0) {
      autoOpenTimeoutId = setTimeout(() => {
        if (!this.stateProvider.get("wasOpened")) {
          this.open(this.quizOptions.initialPageIndex, "autopopup");
        }
      }, milliseconds);
      this.stateProvider.set("autoOpenTimeoutId", autoOpenTimeoutId);
    }
  }

  public bind(event: `${QuizEvent}`, callback: (event: any) => void): void {
    this.quizEventEmitter.subscribe(event as QuizEvent, callback);
  }

  public unbind(event: `${QuizEvent}`, callback: (event: any) => void): void {
    this.quizEventEmitter.unsubscribe(event as QuizEvent, callback);
  }

  public getLeadQueryParams() {
    return this.stateProvider.get("queryParams");
  }

  public async transferDynamicPhoneNumber(phone: string): Promise<void> {
    return this.iframeExchangeService.setHeaderPhone(phone);
  }

  public async changeDescriptor(descriptor: string): Promise<void> {
    return this.iframeExchangeService.setDescriptor(descriptor);
  }

  public isInited(): boolean {
    return this.stateProvider.get("initialized");
  }

  public async useModule<M>(moduleInstance: Module & M): Promise<Module & M>;
  public async useModule<M, T = any>(moduleName: ModuleName, ...args: T[]): Promise<Module & M>;
  public async useModule<M, T = any>(entity: ModuleName | (Module & M), ...args: T[]): Promise<Module & M> {
    let module: Module & M;
    if (typeof entity == "string") module = await this.moduleProvider.register<T, M>(entity, ...args);
    else module = this.moduleProvider.add<M>(entity);
    if (!module.isInited) {
      const result = module.init(this);
      if (isPromise(result)) await result;
    }
    return module;
  }

  private async checkStatus(): Promise<void> {
    const result = await this.checkStatusService.check(this.quizOptions.clientId);
    if (!result) {
      this.close();
      this.quizEventEmitter.emit(QuizEvent.DISABLE);
      console.warn(QUIZ_DISABLED_WARN);
    }
  }

  private setInitialState(options: QuizOptionsSchema) {
    this.stateProvider.set("queryParams", getQueryParams(window.location.search.substring(1)));
    this.stateProvider.set("theme", options.quiz_host.includes("v1-dark/index.html") ? "dark" : "light");
    this.stateProvider.set("servicesNum", options.serviceList?.length || 0);
  }

  private setupEventHandlers() {
    this.iframeExchangeService.on<QuizEventData>(IncomingPattern.QUIZ_EVENT, data => {
      this.handleIncomingQuizEvent(data);
    });

    this.iframeExchangeService.on(IncomingPattern.CLOSE, () => this.handleIncomingCloseEvent());
  }

  private handleIncomingQuizEvent(data: QuizEventData): void {
    this.quizEventEmitter.emit(data.event, data.params);

    if (data.event === QuizEvent.LOAD_QUIZ) {
      this.stateProvider.set("wasLoaded", true);
      this.loadQuizHandler();
    }

    if (data.event === QuizEvent.LOAD_CONTENT) {
      this.iframeExchangeService.sendCustomContent(this.quizOptions.customContent);
    }
  }

  private handleIncomingCloseEvent() {
    if (this.quizOptions.closeButton?.onClick) {
      window.open(this.quizOptions.closeButton.onClick, this.quizOptions.closeButton.target);
    } else this.close();
  }

  private async initIframe() {
    this.iframe.createElement();
    await this.iframe.appendToPage();
    this.quizEventEmitter.emit(QuizEvent.IFRAME_ADDED);
  }

  private handleScenario(scenario: Scenario) {
    if (scenario == "fullPage") {
      this.open(undefined, scenario);
      PageOverflowProvider.addOverflowHidden();
    }

    if (scenario == "custom") this.open(undefined, scenario);

    if (scenario == "popup") {
      const popupTimeout = this.storageProvider.getItem("popup");

      if (!popupTimeout) {
        this.setAutopopupTimeout();

        if (this.quizOptions.openOnScroll) {
          this.scrollHandlerProvider.once("enter_bottom", () => {
            this.open(this.quizOptions.initialPageIndex, "scroll");
          });
        }
      }

      this.bind(QuizEvent.OPEN, () => {
        const expiryDate = new Date(new Date().getTime() + 1000 * 60 * 60 * this.quizOptions.autopopupLimit);
        this.storageProvider.addItem("popup", true, expiryDate);

        this.stateProvider.set("lastPageYPosition", ScrollPositionProvider.currentYPosition);
        setTimeout(() => PageOverflowProvider.addOverflowHidden(), 500);
      });

      this.bind(QuizEvent.CLOSE, () => {
        ScrollPositionProvider.currentYPosition = this.stateProvider.get("lastPageYPosition");
        PageOverflowProvider.removeOverflowHidden();
      });
    }
  }

  private loadQuizHandler(): void {
    this.iframeExchangeService.init({
      clientId: this.quizOptions.clientId,
      serviceList: this.quizOptions.serviceList,
      initialPageIndex: this.quizOptions.initialPageIndex,
      queryParams: this.stateProvider.get("queryParams"),
      startPageAutoAction: this.quizOptions.startPageAutoAction,
      customLeadParams: {
        ...this.quizOptions.customLeadParams,
        ...DeviceInfoProvier.getDeviceInfo(),
        test: Boolean(getQueryParam("_dentolo_test")) || this.quizOptions.test,
        lead_pass_token: String(getQueryParam("_dentolo_lpt")),
        roistat_visit: getCookie("roistat_visit"),
        roistat_first_visit: getCookie("roistat_first_visit"),
        serviceNum: this.quizOptions.serviceList.length,
        serviceList: this.quizOptions.serviceList,
        autopopupTimeout: this.quizOptions.autopopupTimeout,
        openOnScrollEnable: this.quizOptions.openOnScroll,
        waEnable: Boolean(this.quizOptions.whatsApp?.enable),
        scenario: this.quizOptions.scenario,
        theme: this.stateProvider.get("theme"),
        _ct_session_id: getCookie("_ct_session_id"),
      },
      closeButton: this.quizOptions.closeButton,
      domain: document.location.hostname,
      location: window.location.href,
      scenario: this.quizOptions.scenario,
      whatsApp: this.quizOptions.whatsApp,
      telegram: this.quizOptions.telegram,
      bonusesStatusFromPlugin: Boolean(this.quizOptions.bonuses),
      accessKey: getQueryParam("api-key"),
      commonServiceList: this.quizOptions.commonServiceList,
      showCommonServiceList: this.quizOptions.showCommonServiceList,
    });
  }
}
