import { boolean, string, object, optional, Infer } from "superstruct";

const doctorSchema = object({
  photo: optional(string()),
  name: string(),
  surname: string(),
  post: string(),
  patronymic: optional(string()),
  experience: optional(string()),
  patients: optional(string()),
});

const headerSchema = object({
  logo: optional(string()),
  descriptor: optional(string()),
  phone: optional(string()),
  link: optional(string()),
});

const applicationSchema = object({
  header: optional(string()),
  subHeader: optional(string()),
  description: optional(string()),
  buttonText: optional(string()),
  visual: optional(boolean()),
});

export const customContentSchema = object({
  enableYandexId: optional(boolean()),
  hideFlyingButton: optional(boolean()),
  footer: optional(string()),
  privacyPolicy: optional(string()),
  moreInfo: optional(string()),
  backgroundImage: optional(string()),
  doctor: optional(doctorSchema),
  header: optional(headerSchema),
  application: optional(applicationSchema),
});

// Тип для CustomContentSchema
type CustomContentSchema = Infer<typeof customContentSchema>;
