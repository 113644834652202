import Iframe from "../components/iframe/iframe.model";
import { StorageProvider } from "@app/providers/storage.provider";
import { QuizApp } from "./quiz-app";
import { QuizState } from "./quiz-state";
import { quizOptionsSchema } from "./validators/quiz-options.schema";
import { ModuleProvider } from "@app/providers/module.provider";
import AnalyticsModule from "@modules/analytics";
import { Emittable } from "@app/helpers/event-emitter/interfaces/emittable.interface";
import { QuizEvent } from "@app/enums";
import { App, QuizOptions, IStateProvider } from "@app/interfaces";
import { create } from "superstruct";
import { IframeExchangerFactory } from "./iframe-exchanger-factory";
import { CheckStatusService } from "@app/providers/check-status.service";
import { ScrollHandlerProvider } from "@app/providers";
import { SentryService } from "@app/providers/sentry.service";

export class QuizFactory {
  constructor(
    private readonly storageProvider: StorageProvider,
    private readonly quizEventEmitter: Emittable<QuizEvent>,
    private readonly iframeExchengerFactory: IframeExchangerFactory,
    private readonly stateProvider: IStateProvider<QuizState>,
    private readonly checkStatusService: CheckStatusService,
    private readonly moduleProvider: ModuleProvider,
    private readonly scrollHandlerProvider: ScrollHandlerProvider,
    private readonly sentryService: SentryService,
  ) {}

  public create(options: QuizOptions): App {
    const parsedOptions = create(options, quizOptionsSchema);
    const iframe = new Iframe(parsedOptions.quiz_host, parsedOptions.scenario, parsedOptions.iframe);
    const iframeExchangeService = this.iframeExchengerFactory.create(parsedOptions.quiz_host);

    const quiz = new QuizApp(
      iframe,
      parsedOptions,
      this.quizEventEmitter,
      this.stateProvider,
      this.storageProvider,
      this.checkStatusService,
      iframeExchangeService,
      this.moduleProvider,
      this.scrollHandlerProvider,
    );

    this.addAnalyticsModule(quiz);

    return quiz;
  }

  private addAnalyticsModule(quiz: App) {
    const analyticsData = [{ type: "dentolo_ya_metrika" as "dentolo_ya_metrika" }];

    quiz
      .useModule(new AnalyticsModule(analyticsData))
      .then(() => {
        this.quizEventEmitter.emit(QuizEvent.CODE_LOADED, { version: "v3" });
      })
      .catch(e => {
        this.sentryService.captureException(e);
      });
  }
}
