import { EventEmitter } from "@app/helpers/event-emitter/event-emitter";
import { PostMessageService } from "./post-message.service";
import { Connector } from "../interfaces/connector.interface";
import { extractHostname } from "@app/utils";
import { isPromise } from "@app/utils/is-promise";
import { uid } from "@app/utils/uid";
import { GetIframeWindowService } from "./get-iframe-window.service";
import { Emittable } from "@app/helpers/event-emitter/interfaces/emittable.interface";

export interface PostMessagePacket<P extends string = string, D = Record<string, any>> {
  id: string;
  connectionId: string;
  pattern: `request:${P}` | `response:${string}`;
  data: D;
}

export class PostMessageParentConnector implements Connector {
  constructor(
    private readonly postMessageService: PostMessageService,
    private readonly eventEmitter: Emittable<"established" | `request:${string}` | `response:${string}`>,
    private readonly getIframeWindowService: GetIframeWindowService,
    private readonly childUrl: `https://${string}` | `http://${string}`,
  ) {}

  private connectionId: string;
  private established: boolean = false;
  private childWindow: Window;
  private listenerRegistered: boolean = false;
  private connectPromise: Promise<void>;

  public connect() {
    if (this.listenerRegistered) return this.connectPromise;
    this.listenerRegistered = true;
    this.postMessageService.listen<PostMessagePacket>(this.onMessageReceived.bind(this));

    this.connectPromise = new Promise(resolve => {
      this.eventEmitter.once("established", resolve);
    });

    return this.connectPromise;
  }

  private async onMessageReceived(packet: PostMessagePacket) {
    if (packet.pattern === "request:CREATE_CONNECTION") {
      const result = await this.handleCreateConnection(packet);
      if (result) {
        this.connectionId = result.connectionId;
        this.childWindow = result.childWindow;
        this.postMessageService.send(result.response, this.childWindow);
        this.eventEmitter.emit("established");
        this.established = true;
      }
    } else {
      if (packet.id && packet.connectionId == this.connectionId) {
        this.eventEmitter.emit(packet.pattern, packet);
      }
    }
  }

  private async handleCreateConnection(packet: PostMessagePacket) {
    if (extractHostname(this.childUrl) !== extractHostname(packet.data.url)) {
      return;
    }

    const childWindow = await this.getIframeWindowService.get(this.childUrl);
    const response = this.createResponsePacket(packet, undefined);

    return {
      connectionId: packet.connectionId,
      childWindow,
      response,
    };
  }

  public async send<P extends string, DTO = {}, RESP = any>(
    pattern: P,
    dto: DTO,
    attempt = 1,
  ): Promise<RESP> {
    if (!this.established || !this.childWindow) {
      await new Promise<void>(resolve => {
        this.eventEmitter.once("established", resolve);
      });
      if (!this.childWindow) {
        throw new Error("Cannot send message: childWindow is undefined");
      }
    }
    const packet = this.createRequestPacket(pattern, dto);
    this.postMessageService.send(packet, this.childWindow);

    return new Promise<RESP>((resolve, reject) => {
      const timer = setTimeout(async () => {
        if (attempt < 3)
          this.send(pattern, dto, attempt + 1)
            .then(resolve)
            .catch(reject);
        else {
          reject(`timeout of requesting ${packet.pattern}, dto: ${JSON.stringify(dto)}`);
        }
      }, 2000);
      this.eventEmitter.once(`response:${packet.id}`, (packet: PostMessagePacket<P, RESP>) => {
        clearTimeout(timer);
        resolve(packet.data);
      });
    });
  }

  public on<P extends string = string, D = {}, R = any>(pattern: P, callback: (data: D) => Promise<R> | R) {
    return this.eventEmitter.subscribe<PostMessagePacket<P, D>>(`request:${pattern}`, async packet => {
      let result = callback(packet.data);
      if (isPromise(result)) result = await result;
      const response = this.createResponsePacket(packet, result);
      this.postMessageService.send(response, this.childWindow);
    });
  }

  private createRequestPacket<P extends string = string, D = any>(
    pattern: P,
    data: D,
  ): PostMessagePacket<P, D> {
    return { pattern: `request:${pattern}`, data, id: uid(21), connectionId: this.connectionId };
  }

  private createResponsePacket<P extends string = string, R = any>(
    requestPacket: PostMessagePacket<P, any>,
    response: R,
  ): PostMessagePacket<P, R> {
    return {
      pattern: `response:${requestPacket.id}`,
      data: response,
      id: requestPacket.id,
      connectionId: this.connectionId,
    };
  }
}
