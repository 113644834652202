export enum QuizEvent {
  CODE_LOADED = "code_loaded",
  IFRAME_ADDED = "iframe_added",
  INIT = "init",
  LOAD_QUIZ = "load_quiz",
  LOAD_CONTENT = "load_content",
  OPEN = "open",
  START_QUIZ = "start_quiz",
  START_QUIZ_COMMON = "start_quiz_0",
  START_BY_FLYING_BTN = "start_by_flying_btn",
  START_BY_SCROLL = "start_by_scroll",
  START_BY_MAIN_BUTTON = "start_by_main_button",
  START_BY_TIMER = "start_by_timer",
  CLOSE = "close",
  GET_LEAD = "get_lead",
  CHOOSE_SERVICE = "choose_service",
  GET_APPOINTMENT = "get_appointment",
  REACH_PHONE_FORM = "reach_phone_form",
  START_TYPING_PHONE = "start_typing_phone",
  CLICK_HEADER_PHONE = "click_header_phone",
  REACH_QUESTION = "reach_question",
  CLICK_TRADEMARK = "click_trademark",
  SHOW_FLYING_BTN = "show_flying_btn",
  SHOW_PHONE_FORM_VISUAL = "show_phone_form_visual",
  CLICK_ADDITIONAL_BUTTON = "click_additional_button",
  DEFINE_SERVICE = "define_service",
  CLICK_WHATSAPP_BUTTON = "click_whatsapp_button",
  CLICK_BUTTON_IN_WHATSAPP_INSTRUCTION = "click_button_in_whatsapp_instruction",
  EVENT_GO_TO_SERVICE_SELECTION = "go_to_service_selection",
  SEND_PARAMS = "send_params",
  EVENT_GET_FORM_TYPE = "get_form_type",
  GET_GEODATA = "get_geodata",
  CLOSE_FIX_BLOCK = "close_fix_block",
  LOAD_MODULE = "load_module",
  CLICK_AGREEMENT = "click_agreement",
  GO_TO_SERVICE_SELECTION = "go_to_service_selection",
  OPEN_SIGN_UP_BY_TIMER = "open_sign_up_by_timer",
  DISABLE = "disable",
  SEND_ECOMMERCE = "send_ecommerce",
  CLICK_YA_ID_BUTTON = "click_ya_id_button",
  YA_ID_AUTH = "ya_id_auth",
  SHOW_YA_ID_BUTTON = "show_ya_id_button",
  CLICK_TELEGRAM_BUTTON = "click_telegram_button",
}
