import { EventEmitter } from "@app/helpers";
import { Analytics } from "../interfaces/analytics.interface";
import { YaMetrikaOptions } from "../interfaces/ya-metrika-options.interface";
import { App } from "@app/interfaces/app.interface";

declare global {
  interface Window {
    dentolo_quiz_ec: any[];
    ym: any;
  }
}

export class YaMetrikaCounter implements Analytics {
  constructor(private readonly options: YaMetrikaOptions) {}

  private ym: any;
  private _inited: boolean = false;
  private eventEmitter = new EventEmitter();

  public isInited(): boolean {
    return this._inited;
  }

  public async init(quiz: App) {
    const SRC = "https://mc.yandex.ru/metrika/tag.js";
    const $this = this;

    this.ym =
      window.ym ||
      function (...args: any[]) {
        $this.ym.a = $this.ym.a || [];
        $this.ym.a.push(args);
      };

    this.ym.l = new Date().getTime();
    window.ym = this.ym;

    window.dentolo_quiz_ec = window.dentolo_quiz_ec || [];

    const isAlreadyExist = document.querySelector(`[src="${SRC}"]`);

    if (!isAlreadyExist) {
      const script = document.createElement("script");
      const firstScript = document.getElementsByTagName("script")[0];
      script.async = true;
      script.src = SRC;
      firstScript?.parentNode?.insertBefore(script, firstScript);
      await new Promise<void>(resolve => {
        script.onload = () => resolve();
      });
    }

    window.ym(this.options.counter, "init", {
      trackLinks: true,
      clickmap: true,
      webvisor: this.options.webvisor,
      accurateTrackBounce: true,
      triggerEvent: true,
      ecommerce: "dentolo_quiz_ec",
    });

    this.eventEmitter.emit("init", null);
    this._inited = true;

    if (this.options.webvisor) {
      quiz.sendCustomData({
        cmd: "enable_webvisor",
        payload: {
          counter: this.options.counter,
          domain: window.location.hostname,
        },
      });
    }

    this.exec("getClientID", (clientID: string) => {
      quiz.sendCustomLeadParams({ yaClientID: clientID });
    });
  }

  public track(event: string, params?: Record<string, any>) {
    return this.exec("reachGoal", event, params);
  }

  public setParams(params: Record<string, any>) {
    return this.exec("params", params);
  }

  public setEcommerce(data: Record<string, any>) {
    window.dentolo_quiz_ec.push({ ecommerce: data });
  }

  private exec(...args: any): Promise<any> {
    if (this.isInited()) return Promise.resolve(window.ym(this.options.counter, ...args));
    else {
      return new Promise<any>(resolve => {
        this.eventEmitter.subscribe("init", () => {
          return resolve(window.ym(this.options.counter, ...args));
        });
      });
    }
  }
}
