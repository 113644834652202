export function getQueryParams(queryString: string): Readonly<Record<string, string>> {
  const result: Record<string, string> = {};
  const decode = (s: string) => decodeURIComponent(s.replace(/\+/g, " "));
  const keyValues = queryString.split('&');

  keyValues.forEach(pair => {
    let key = pair.split('=');
    if (key[0] || key[1]) result[decode(key[0])] = decode(key[1] || "");
  });

  return result;
} 