import { App } from "@app/interfaces";

export abstract class Module {
  protected is_inited: boolean = false;
  public abstract readonly name: string;

  public get isInited() {
    return this.is_inited;
  }

  public async init(app: App): Promise<void> {
    if (this.isInited) return;
    this.is_inited = true;
    return this.setup(app);
  }

  protected abstract setup(app: App): Promise<void>;
}
