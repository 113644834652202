const osPatterns = [
  { name: "Windows", pattern: /Windows NT ([0-9.]+)/ },
  { name: "Android", pattern: /Android ([0-9.]+)/ },
  { name: "iOS", pattern: /iPhone OS ([0-9_]+)/ },
  { name: "iOS", pattern: /iPad; CPU OS ([0-9_]+)/ },
  { name: "macOS", pattern: /Mac OS X ([0-9_]+)/ },
  { name: "Linux", pattern: /Linux/ },
];

const devicePatterns = [
  { name: "iPhone", pattern: /iPhone/ },
  { name: "iPad", pattern: /iPad/ },
  { name: "iPod", pattern: /iPod/ },
  { name: "Android", pattern: /Android/ },
  { name: "Windows Phone", pattern: /Windows Phone/ },
];

const browserPatterns = [
  { name: "Edge", pattern: /Edg\/([0-9.]+)/ }, // Edge проверяется раньше Chrome
  { name: "Brave", pattern: /Brave\/([0-9.]+)/ }, // Brave
  { name: "Opera", pattern: /OPR\/([0-9.]+)/ }, // Opera
  { name: "Chrome", pattern: /Chrome\/([0-9.]+)/ },
  { name: "Firefox", pattern: /Firefox\/([0-9.]+)/ },
  { name: "Safari", pattern: /Version\/([0-9.]+) Safari/ },
  { name: "IE", pattern: /MSIE ([0-9.]+)/ },
  { name: "IE", pattern: /Trident\/.*rv:([0-9.]+)/ },
];

export class DeviceInfoProvier {
  static os = {
    name: () => DeviceInfoProvier.getOSName(),
    version: () => DeviceInfoProvier.getOSVersion(),
  };

  private static get userAgent() {
    return window.navigator.userAgent;
  }

  static getDeviceInfo() {
    return {
      os_name: DeviceInfoProvier.getOSName(),
      os_version: DeviceInfoProvier.getOSVersion(),
      device_model: DeviceInfoProvier.getModel(),
      device_brand: DeviceInfoProvier.getBrand(),
      device_type: DeviceInfoProvier.getDeviceType(),
      browser_name: DeviceInfoProvier.getBrowser(),
      browser_version: DeviceInfoProvier.getBrowserVersion(),
    };
  }

  static isMobile(): boolean {
    const deviceType = DeviceInfoProvier.getDeviceType();
    return deviceType === "phone" || deviceType === "tablet";
  }

  static ios(): boolean {
    return DeviceInfoProvier.os.name() === "iOS";
  }

  static getOSName(): string {
    for (const osPattern of osPatterns) {
      if (this.userAgent.match(osPattern.pattern)) {
        return osPattern.name;
      }
    }
    return "Unknown";
  }

  static getOSVersion(): string {
    for (const osPattern of osPatterns) {
      const match = this.userAgent.match(osPattern.pattern);
      if (match) {
        // Проверка наличия match[1] перед вызовом replace
        return match[1] ? match[1].replace(/_/g, ".") : "";
      }
    }
    return "";
  }

  static getModel(): string {
    for (const devicePattern of devicePatterns) {
      if (this.userAgent.match(devicePattern.pattern)) {
        return devicePattern.name;
      }
    }
    if (this.userAgent.match(/Windows NT/)) {
      return "PC";
    } else if (this.userAgent.match(/Macintosh/)) {
      return "Mac";
    } else if (this.userAgent.match(/Linux/)) {
      return "Linux PC";
    }
    return "Unknown";
  }

  static getBrand(): string {
    for (const devicePattern of devicePatterns) {
      if (this.userAgent.match(devicePattern.pattern)) {
        return devicePattern.name.includes("iPhone") ||
          devicePattern.name.includes("iPad") ||
          devicePattern.name.includes("iPod")
          ? "Apple"
          : "Unknown";
      }
    }
    if (this.userAgent.match(/Windows NT/)) {
      return "Microsoft";
    } else if (this.userAgent.match(/Macintosh/)) {
      return "Apple";
    } else if (this.userAgent.match(/Linux/)) {
      return "Unknown";
    }
    return "Unknown";
  }

  static getDeviceType(): string {
    for (const devicePattern of devicePatterns) {
      if (this.userAgent.match(devicePattern.pattern)) {
        return devicePattern.name.includes("iPad") ||
          (devicePattern.name === "Android" && !this.userAgent.match(/Mobile/))
          ? "tablet"
          : "phone";
      }
    }
    if (
      this.userAgent.match(/Windows NT/) ||
      this.userAgent.match(/Macintosh/) ||
      this.userAgent.match(/Linux/)
    ) {
      return "desktop";
    }
    return "Unknown";
  }

  static getBrowser(): string {
    for (const browserPattern of browserPatterns) {
      if (this.userAgent.match(browserPattern.pattern)) {
        return browserPattern.name;
      }
    }
    return "Unknown";
  }

  static getBrowserVersion(): string {
    for (const browserPattern of browserPatterns) {
      const match = this.userAgent.match(browserPattern.pattern);
      if (match) {
        return match[1];
      }
    }
    return "";
  }
}
