import { boolean, string, object, optional, Infer } from "superstruct";

export const whatsappSchema = object({
  enable: boolean(),
  phone: string(),
  onFirstScreen: optional(boolean()),
  inDoctorBlock: optional(boolean()),
  start_page_text: optional(string()),
  form_page_text: optional(string()),
  doctor_text: optional(string()),
  start_page_phone: optional(string()),
  form_page_phone: optional(string()),
  doctor_phone: optional(string()),
  remove_lead_id_start_page: optional(boolean()),
  remove_lead_id_form_page: optional(boolean()),
  remove_lead_id_doctor: optional(boolean()),
});

// Тип для WhatsappSchema
export type WhatsappSchema = Infer<typeof whatsappSchema>;
